main {
  display: flex;
  flex: 1 1 50%;
  flex-direction: row;
  column-gap: 1rem;

  padding: 2rem;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;

    &.answer-wrapper {
      align-items: center;
      @media screen and (max-width: 1200px) {
        padding: 4rem 0;
      }
    }
  }
}