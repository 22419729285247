.crystal-ball {
  position: relative;

  .ball {
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    filter: drop-shadow(0 0 22px #72d6e5);
    animation: magic-shadow 10s infinite both;


    /* ff 3.6+ */
    background: -moz-radial-gradient(circle at 50% 50%, rgb(101, 208, 241) 0%, rgba(141, 68, 255, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background: -webkit-radial-gradient(circle at 50% 50%, rgb(101, 208, 241) 0%, rgba(141, 68, 255, 1) 100%);

    /* opera 11.10+ */
    background: -o-radial-gradient(circle at 50% 50%, rgb(101, 208, 241) 0%, rgba(141, 68, 255, 1) 100%);

    /* ie 10+ */
    background: -ms-radial-gradient(circle at 50% 50%, rgb(101, 208, 241) 0%, rgba(141, 68, 255, 1) 100%);

    /* global 92%+ browsers support */
    background: radial-gradient(circle at 50% 50%, rgb(101, 208, 241) 0%, rgba(141, 68, 255, 1) 100%);

    &.drawing {
      animation: drawing 5s both linear;
    }

    @media screen and (max-width: 768px) {
      width: 300px;
      height: 300px;
    }
    @media screen and (max-width: 576px) {
      width: 230px;
      height: 230px;
    }

    .answer {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 40px;
      transform: translate(-50%, -50%);
      white-space: break-spaces;
      word-break: break-word;
      text-align: center;

      -webkit-animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) backwards;
      animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) backwards;

      z-index: 3;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }


    }

    .magic {
      position: absolute;
      left: 10px;
      bottom: -5px;

      animation: magic-anim 10s infinite both;
      @media screen and (max-width: 768px) {
        width: 300px;
      }
      @media screen and (max-width: 576px) {
        width: 230px;
        bottom: 1px;
      }
    }
  }

  .base {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 300px;
    height: 100px;
    background-color: #381b08;
    border-radius: 100px 100px 20px 20px;
    box-shadow: 0 0 5px #1a1c23;
    filter: drop-shadow(1px -10px 52px #000);
    z-index: -1;

    @media screen and (max-width: 768px) {
      width: 200px;
      height: 50px;
    }
    @media screen and (max-width: 576px) {
      width: 150px;
      height: 45px;
    }
  }
}

.wrap {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 8rem 0;
}

.button {
  min-width: 250px;
  min-height: 60px;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #4FD1C5;
  background: linear-gradient(90deg, rgba(129, 230, 217, 1) 0%, rgba(79, 209, 197, 1) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79, 209, 197, .64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 20px;

  @media screen and (max-width: 576px) {
    min-width: 200px;
    font-size: 15px;
  }

  &:hover, &:focus {
    color: #313133;
    transform: translateY(-6px);
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 6px solid #00FFCB;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 3s infinite;

    &:hover::after, &:focus::after {
      animation: none;
      display: none;
    }
  }

  &:before {
    content: '';
    border-radius: 1000px;
    min-width: calc(270px + 12px);
    min-height: calc(60px + 12px);
    border: 6px solid #00FFCB;
    box-shadow: 0 0 60px rgba(0, 255, 203, .64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;

    &:hover::before, &:focus::before {
      opacity: 1;
    }
  }
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 260px;
    height: 100px;
    opacity: 0;
  }
}

@keyframes magic-anim {
  from {
    transform: skew(-1deg) rotate(1deg);
  }
  20% {
    transform: skew(1deg) rotate(-1deg);
  }
  40% {
    transform: skew(-2deg) rotate(-2deg);

  }
  60% {
    transform: skew(0deg) rotate(0deg);

  }
  80% {
    transform: skew(1deg) rotate(1deg);

  }
  to {
    transform: skew(-1deg) rotate(1deg);
  }
}

@keyframes magic-shadow {
  from {
    filter: drop-shadow(0 0 22px #72d6e5);
  }
  20% {
    filter: drop-shadow(0 0 42px #8273ea);
  }
  40% {
    filter: drop-shadow(0 0 32px #4f8ce7);
  }
  60% {
    filter: drop-shadow(0 0 38px #88cb86);
  }
  80% {
    filter: drop-shadow(0 0 28px #a46fdc);
  }
  to {
    filter: drop-shadow(0 0 22px #72d6e5);
  }
}
.scale-up-center-answer {
  opacity: 0;
  -webkit-animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards 2.5s;
  animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards 2.5s;
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    filter: blur(1.5rem);
  }
  50% {
    filter: blur(1rem);
  }
  100% {
    -webkit-transform: scale(1);
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    filter: blur(1.5rem);
  }
  50% {
    filter: blur(1rem);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    filter: blur(0)
  }
}

@keyframes drawing {
  from {
    filter: hue-rotate(0);
  }
  50% {
    filter: hue-rotate(230deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}