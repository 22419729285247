.footer-wrapper {
  overflow: hidden;
  margin: 0 auto;

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    position: relative;
    margin: 30px 0;
    width: 100vw;
    letter-spacing: 2px;
    font-size: 14px;
    text-align: center;

    &.slide-in-bottom {
      -webkit-animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .social-container {
      .social {
        font-size: 28px;
        margin: 20px;
        color: var(--color-additonal);
        transition: 0.5s;
        cursor: pointer;
        @media screen and (max-width: 1400px) {
          font-size: 24px;
        }
        @media screen and (max-width: 576px) {
          margin: 10px;
        }


        &:hover {
          transform: scale(120%);
        }

        &.fa-linkedin-in {
          color: #4c98f1;
        }

        &.fa-github {
          color: #cdd9e5;
        }

        &.fa-whatsapp {
          color: #4cca5a;
        }

      &.pd {
        display: inline-block;
        margin: 0;
        font-size: 28px;
        font-weight: 700;
        margin: 0 10px;
        text-decoration: none;
        font-family: Comfortaa;

        .p {
          color: #FF8D6B;
        }
        .d {
          color: #5AFFCF;
        }
      }
    }
  }
    .copyrights {
      color: azure;
      @media screen and (max-width: 1400px) {
        font-size: 10px;
      }
    }
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


