.solutions {
  background-color: #FCE38A;
  color: #1a1c23;
  border-radius: 20px;
  padding: 2rem;
  margin-top: 2rem;
  width: 100%;
  box-shadow: 0 0 5px #1a1c23;

  ol {
    font-family: Kalam, Montserrat, sans-serif;
    font-size: 20px;
    padding: 1.5rem;
    li {
      margin-bottom: 10px;
      word-break: break-all;
      .fa-solid {
        margin-left: 1rem;
        color: darkred;
        cursor: pointer;
      }
    }
  }
  button {
    display: block;
    background: transparent;
    padding: 10px;
    font-size: 20px;
    border: 2px solid #1a1c23;
    border-radius: 10px;
    margin: 0 auto;
    cursor: pointer;
  }
}