h1 {
  color: #fae159;
  text-shadow: 0 0 3px black;
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 1rem;

  margin: 2rem;
  width: fit-content;


  span.advisor {
    display: block;
    font-weight: 700;
    font-size: 2rem;
    letter-spacing: 5px;
  }

  .underline {
    width: 100%;
    height: 3px;
    background-color: #fae159;
    margin-top: 10px;
    border-radius: 10%;

  }
}